#IDX-quicksearchForm-43178 .IDX-qsInput {
  margin: 0 2px;
  height: 40px;
  border: 1px solid lightgray;
  border-radius: 120px;
  margin-top: 5px;
  padding: 0 18px;
  width: 100%;
}
#IDX-quicksearchForm-43178 .IDX-qsInput:focus {
  outline: none;
  border: 1px solid tomato;
}

#IDX-quicksearchForm-43178 {
  width: 100%;
  display: inline-block;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px;
  align-items: flex-end;
  justify-content: center !important;
  cursor: pointer;
}

#IDX-qsSubmit-43178 {
  background: #ea5819;
  color: white;
  border: none;
  cursor: pointer;
}
